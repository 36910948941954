/**

 * H5与原生通信（不需要引入第三方库）

 * @param fnName 方法名称（原生与H5中需要一致） getParameters | logOut

 * @param callback 获取的数据回调

 */
const u = navigator.userAgent
export const isIos = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/) // ios终端
export const isAndroid = u.indexOf('Android') > -1 || u.indexOf('Linux') > -1

export const sendNativeMessage = (fnName, actionCode, callback, args) => {
  if (isIos) {
    const iOSCallback = 'llwkCallback'
    if (fnName === 'LLWKFormAppAction') {
      window[iOSCallback] = (data, value) => {
        console.log('---ios---', data, value)
        callback(value)
        delete window.iOSCallback
      }
    }

    const data = {
      action: actionCode,
      callback: iOSCallback,
      ...args
    }

    // IOS环境使用方法
    window.webkit &&
          window.webkit.messageHandlers &&
          window.webkit.messageHandlers[fnName] &&
          window.webkit.messageHandlers[fnName].postMessage(data)
          // window.webkit.messageHandlers['LLWKFormAppAction'].postMessage({action:'closeWebView'});
  }

  if (isAndroid) {
    try {
      // window.nativeCaller&&
      if (window.android) {
        let result
        if (args) {
          result = window.android[actionCode](JSON.stringify(args))
        } else {
          result = window.android[actionCode]()
        }
        callback && callback(result)
      }
    } catch (e) {
      console.log(e)
    }
    // 安卓使用方法
  }
}

//   调用方式

//  sendNativeMessage('getParameters', res => {

// // 需要获取的字段名称

// const params =

//  ["authorization", "propertyProjectId", "propertyProjectName", "account", "accountID"] ;

//       params.forEach(item => {

//         setStorerage(item , res[item])

//       })

//     });

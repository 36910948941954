<template>
  <RouterView :key="routeKey" />
</template>
<script setup>
import { computed } from 'vue'
import { RouterView, useRoute } from 'vue-router'
const route = useRoute()
const routeKey = computed(() => {
  return route.path + Math.random()
})
</script>

    <style scoped>
</style>

import { createRouter, createWebHashHistory, createWebHistory } from 'vue-router'

const routes = [
  {
    path: '/anchorset',
    name: 'AnchorSet',
    component: () => import('../../views/H5/AnchorSet.vue'),
    meta: {
      title: '主播入驻·'
    }
  }, {
    path: '/recharge',
    name: 'ReCharge',
    component: () => import('../../views/H5/Recharge.vue'),
    meta: {
      title: '浪浪直播'
    }
  }, {
    path: '/rechargeByMoney',
    name: 'RechargeByMoney',
    component: () => import('../../views/H5/RechargeByMoney.vue'),
    meta: {
      title: '浪浪直播充值'
    }
  }, {
    path: '/hwrecharge',
    name: 'HWRecharge',
    component: () => import('../../views/H5/HWRecharge.vue'),
    meta: {
      title: '浪浪直播充值'
    }
  }, {
    path: '/pay:langhua:money:userId:nickname:headPic:tplId',
    name: 'ReChargePay',
    component: () => import('../../views/H5/RechargePay.vue'),
    meta: {
      title: '浪浪直播充值'
    }
  }, {
    path: '/paymiddle',
    name: 'PayMiddle',
    component: () => import('../../views/H5/Pay/middle.vue'),
    meta: {
      title: '浪浪直播充值'
    }
  }, {
    path: '/payresult',
    name: 'PayResult',
    component: () => import('../../views/H5/payResult.vue'),
    meta: {
      title: '浪浪直播充值'
    }
  }, {
    path: '/payfailure',
    name: 'PayFailure',
    component: () => import('../../views/H5/Pay/failure.vue'),
    meta: {
      title: '浪浪直播充值'
    }
  }, {
    path: '/trade',
    name: 'Trade',
    component: () => import('../../views/H5/trade.vue'),
    meta: {
      title: '浪浪直播充值'
    }
  }, {
    path: '/experience',
    name: 'Experience',
    component: () => import('../../views/H5/Experience/index.vue'),
    meta: {
      title: '等级明细'
    }
  }, {
    path: '/download',
    name: 'Download',
    component: () => import('../../views/H5/Download/index.vue'),
    meta: {
      title: '落地页'
    }
  }, {
    path: '/landpage',
    name: 'LandPage',
    component: () => import('../../views/H5/LandPage/index.vue'),
    meta: {
      title: '落地页'
    }
  }, {
    path: '/downloadpage',
    name: 'DownloadPage',
    component: () => import('../../views/H5/LandPageNew/index.vue'),
    meta: {
      title: '落地页'
    }
  }, {
    path: '/invite',
    name: 'Invite',
    component: () => import('../../views/H5/Invite/index.vue'),
    meta: {
      title: '邀友奖励'
    }
  }, {
    path: '/invitelink',
    name: 'InviteLink',
    component: () => import('../../views/H5/Invite/link.vue'),
    meta: {
      title: '邀友'
    }
  }, {
    path: '/inviteCode',
    name: 'InviteCode',
    component: () => import('../../views/H5/Invite/codeInput.vue'),
    meta: {
      title: '填写邀请码'
    }
  }, {
    path: '/withdraw',
    name: 'WithDraw',
    component: () => import('../../views/H5/WithDraw/index.vue'),
    meta: {
      title: '提现'
    }
  }, {
    path: '/drawList',
    name: 'WithDrawList',
    component: () => import('../../views/H5/WithDraw/list.vue'),
    meta: {
      title: '提现记录'
    }
  }, {
    path: '/dreamGame',
    name: 'DreamGame',
    component: () => import('../../views/H5/Games/Dream/index.vue'),
    meta: {
      title: '奇幻梦境'
    }
  }, {
    path: '/babel',
    name: 'Babel',
    component: () => import('../../views/H5/Games/Babel/index.vue'),
    meta: {
      title: '通天塔'
    }
  }, {
    path: '/fishpond',
    name: 'FishPond',
    component: () => import('../../views/H5/Games/FishPond/index.vue'),
    meta: {
      title: '欢乐鱼塘'
    }
  }, {
    path: '/game/farm',
    name: 'Farm',
    component: () => import('../../views/H5/Games/Farm/index.vue'),
    meta: {
      title: '开心农场'
    }
  }, {
    path: '/game/dragon',
    name: 'Dragon',
    component: () => import('../../views/H5/Games/Dragon/index.vue'),
    meta: {
      title: '屠龙战场'
    }
  }, {
    path: '/rookiefish',
    name: 'RookieFish',
    component: () => import('../../views/H5/Games/RookieFish/index.vue'),
    meta: {
      title: '新手鱼塘'
    }
  }, {
    path: '/dressup',
    name: 'DressUp',
    component: () => import('../../views/H5/Dressup/index.vue'),
    meta: {
      title: '装扮商城'
    }
  }, {
    path: '/takenumber',
    name: 'TakeNumber',
    component: () => import('../../views/H5/Dressup/takeNumber.vue'),
    meta: {
      title: '靓号系统上线福利'
    }
  }, {
    path: '/nationalday',
    name: 'NationalDay',
    component: () => import('../../views/H5/NationalDay/index.vue'),
    meta: {
      title: '欢节8天乐'
    }
  }, {
    path: '/weekstar',
    name: 'WeekStar',
    component: () => import('../../views/H5/Activity/WeekStar/index.vue'),
    meta: {
      title: '周星'
    }
  }, {
    path: '/latter',
    name: 'Latter',
    component: () => import('../../views/H5/Latter/index.vue'),
    meta: {
      title: '天梯赛'
    }
  }, {
    path: '/latterBanner',
    name: 'LatterBanner',
    component: () => import('../../views/H5/Latter/banner.vue'),
    meta: {
      title: '天梯赛'
    }
  }, {
    path: '/living',
    name: 'Living',
    component: () => import('../../views/H5/AgoraPage/index.vue'),
    meta: {
      title: '浪浪直播'
    }
  }, {
    path: '/wxPubRecharge',
    name: 'wxPubRecharge',
    component: () => import('../../views/H5/Recharge/index.vue'),
    meta: {
      title: '充值'
    }
  }, {
    path: '/hostTask',
    name: 'HostTask',
    component: () => import('../../views/H5/HostTask/index.vue'),
    meta: {
      title: '主播任务'
    }
  }, {
    path: '/iceSnow',
    name: 'IceSnow',
    component: () => import('../../views/H5/Activity/IceSnowFestival/index.vue'),
    meta: {
      title: '冰雪节'
    }
  }, {
    path: '/newiceSnow',
    name: 'NewIceSnow',
    component: () => import('../../views/H5/Activity/NewIceSnowFestival/index.vue'),
    meta: {
      title: '冰雪节'
    }
  }, {
    path: '/newiceSnowbanner',
    name: 'NewIceSnowBanner',
    component: () => import('../../views/H5/Activity/NewIceSnowFestival/banner.vue'),
    meta: {
      title: '冰雪节'
    }
  }, {
    path: '/game/garden',
    name: 'Garden',
    component: () => import('../../views/H5/Games/Garden/index.vue'),
    meta: {
      title: '浪浪花园'
    }
  }, {
    path: '/game/war',
    name: 'War',
    component: () => import('../../views/H5/Games/MythicWar/index.vue'),
    meta: {
      title: '神话战争'
    }
  }, {
    path: '/maishi',
    name: 'MaiShi',
    component: () => import('../../views/H5/MaiShi/index.vue'),
    meta: {
      title: '麦时奖励'
    }
  },

  // {
  //   path: '/springfestival',
  //   name: 'springFestival',
  //   component: () => import('../../views/H5/Activity/SpringFestival/index.vue'),
  //   meta: {
  //     title: '新春主播宝箱'
  //   }
  // },
  // {
  //   path: '/newyear',
  //   name: 'NewYear',
  //   component: () => import('../../views/H5/Activity/NewYear/index.vue'),
  //   meta: {
  //     title: '新春大鸿运'
  //   }
  // },
  {
    path: '/charm',
    name: 'Charm',
    component: () => import('../../views/H5/Activity/Charm/index.vue'),
    meta: {
      title: '魅力争霸赛'
    }
  }, {
    path: '/luckyday',
    name: 'LuckyDay',
    component: () => import('../../views/H5/Activity/LuckyDay/index.vue'),
    meta: {
      title: '超级幸运日'
    }
  }, {
    path: '/swimmingpool',
    name: 'SwimmingPool',
    component: () => import('../../views/H5/Activity/SwimmingPool/index.vue'),
    meta: {
      title: '泳池派对'
    }
  }, {
    path: '/qixi',
    name: 'Qixi',
    component: () => import('../../views/H5/Activity/Qixi/index.vue'),
    meta: {
      title: '七夕鹊桥会'
    }
  }, {
    path: '/luckypiece',
    name: 'LuckyPiece',
    component: () => import('../../views/H5/LuckyPiece/index.vue'),
    meta: {
      title: '幸运碎片兑换'
    }
  }, {
    path: '/substitute',
    name: 'Substitute',
    component: () => import('../../views/H5/Substitute.vue'),
    meta: {
      title: '代充记录'
    }
  }, {
    path: '/glorywar',
    name: 'GloryWar',
    component: () => import('../../views/H5/Activity/GloryWar/index.vue'),
    meta: {
      title: '荣耀之战'
    }
  }, {
    path: '/glorywarbanner',
    name: 'GloryWarBanner',
    component: () => import('../../views/H5/Activity/GloryWar/banner.vue'),
    meta: {
      title: '荣耀之战'
    }
  }, {
    path: '/stone',
    name: 'Stone',
    component: () => import('../../views/H5/Stone/index.vue'),
    meta: {
      title: '灵石商店'
    }
  }, {
    path: '/game/furance',
    name: 'Furance',
    component: () => import('../../views/H5/Games/Furance/index.vue'),
    meta: {
      title: '灵石炼丹炉'
    }
  }, {
    path: '/invest/card',
    name: 'InvestCard',
    component: () => import('../../views/H5/Invest/cardcharge.vue'),
    meta: {
      title: '银行卡支付'
    }
  }, {
    path: '/invest/cardcode',
    name: 'cardcode',
    component: () => import('../../views/H5/Invest/cardcode.vue'),
    meta: {
      title: '银行卡支付'
    }
  }, {
    path: '/activity/shenhao',
    name: 'ShenHao',
    component: () => import('../../views/H5/Activity/ShenHao/index.vue'),
    meta: {
      title: '神豪通天榜单'
    }
  }, {
    path: '/activity/halloween',
    name: 'Halloween',
    component: () => import('../../views/H5/Activity/Halloween/index.vue'),
    meta: {
      title: '万圣节'
    }
  }, {
    path: '/halloweenbanner',
    name: 'HalloweenBanner',
    component: () => import('../../views/H5/Activity/Halloween/banner.vue'),
    meta: {
      title: '万圣节'
    }
  }, {
    path: '/farmtycoon',
    name: 'FarmTycoon',
    component: () => import('../../views/H5/Activity/FarmTycoon/index.vue'),
    meta: {
      title: '农场大亨'
    }
  }, {
    path: '/test',
    name: 'Test',
    component: () => import('../../views/H5/test.vue'),
    meta: {
      title: '测试页面'
    }
  }, {
    path: '/demo2',
    name: 'Test',
    component: () => import('../../views/H5/Recharge/index2.vue'),
    meta: {
      title: '测试页面'
    }
  }
]

const GamesRoutes = [
  {
    path: '/taoquan',
    name: 'Taoquan',
    component: () => import('../../views/H5/Games/TaoQuan/index.vue'),
    meta: {
      title: '套圈'
    }
  }, {
    path: '/family/index',
    name: 'FamilyIndex',
    component: () => import('../../views/H5/FamilyManage/index.vue'),
    meta: {
      title: '家族管理'
    }
  }, {
    path: '/family/anchorlist',
    name: 'AnchorList',
    component: () => import('../../views/H5/FamilyManage/anchorList.vue'),
    meta: {
      title: '主播列表'
    }
  }, {
    path: '/family/anchorincome',
    name: 'AnchorIncomeStatistics',
    component: () => import('../../views/H5/FamilyManage/incomeStatistics.vue'),
    meta: {
      title: '主播收益统计'
    }
  }, {
    path: '/family/acceptgift',
    name: 'AcceptGifts',
    component: () => import('../../views/H5/FamilyManage/acceptGift.vue'),
    meta: {
      title: '主播收礼明细'
    }
  }, {
    path: '/family/maishidetail',
    name: 'MaishiDetail',
    component: () => import('../../views/H5/FamilyManage/maishiDetail.vue'),
    meta: {
      title: '主播麦时明细'
    }
  }, {
    path: '/family/income',
    name: 'FamilyIncome',
    component: () => import('../../views/H5/FamilyManage/familyIncome.vue'),
    meta: {
      title: '家族收益'
    }
  }, {
    path: '/family/livesecurity',
    name: 'LiveSecurity',
    component: () => import('../../views/H5/FamilyManage/liveSecurity.vue'),
    meta: {
      title: '直播安全管理'
    }
  }, {
    path: '/family/list',
    name: 'FamilyList',
    component: () => import('../../views/H5/FamilyManage/familyList.vue'),
    meta: {
      title: '家族列表'
    }
  }
]


const ActivitysRoutes = [{
  path: '/activity/newyear25',
  name: 'NewYear25',
  component: () => import('../../views/H5/Activity/NewYear25/index.vue'),
  meta: {
    title: '新春大鸿运'
  }
}, {
  path: '/activity/springfestival25',
  name: 'SpringFestival25',
  component: () => import('../../views/H5/Activity/SpringFestival25/index.vue'),
  meta: {
    title: '新春主播宝箱'
  }
}]

const router = createRouter({
  history: createWebHashHistory('h5'),
  routes: [...GamesRoutes, ...routes, ...ActivitysRoutes]
})

// router.onError((error) => {
//     const pattern = /Loading chunk (\d)+ failed/g
//     const isChunkLoadFailed = error.message.match(pattern)
//     const targetPath = router.history.pending.fullPath
//     if (isChunkLoadFailed) {
//         router.replace(targetPath)
//     }
// })
router.beforeEach((to, from, next) => {
  if (to.meta.title) {
    document.title = to.meta.title
  }
  next()
})

export default router

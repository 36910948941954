import dayjs from 'dayjs'

export function getQueryVariable(variable) {
  const after = window.location.href.split('?', 2)[1]
  if (after) {
    const reg = new RegExp('(^|&)' + variable + '=([^&]*)(&|$)')
    const r = after.match(reg)
    if (r != null) {
      return decodeURIComponent(r[2]).split('#')[0]
    } else {
      return false
    }
  }
}
import Deault_img from '../assets/images/h5/head.png'
const constPath = process.env.VUE_APP_PUBLIC_URL
export const fullPicture = (str) => {
  if (str) {
    return constPath + str
  } else {
    return Deault_img
  }

}

export const fullCDNPicture = (str) => {
  return `https://cdn.langlang88.com/h5-image/${str}`

}

export const getLast3Month = () => {
  const lastMonths = 3
  const arr = []

  for (let i = 0; i < lastMonths; i++) {
    const dys = dayjs().subtract(i, 'months')
    arr.push({
      key: dys.format('YYYY-MM'),
      label: dys.format('YYYY年MM月')
    })
  }
  return arr
}

export function handleCopy(content, cb) {
  const copy_text = content // 拿到想要复制的值

  const input_dom = document.createElement('input') // 创建input元素
  input_dom.value = copy_text // 添加需要复制的内容到value属性
  document.body.appendChild(input_dom) // 向页面底部追加输入框
  input_dom.select() // 选择input元素
  document.execCommand('Copy') // 执行复制命令
  // alert("复制成功！");//弹出提示信息，不同组件可能存在写法不同
  cb && cb()
  // 复制之后再删除元素，否则无法成功赋值
  input_dom.remove() // 删除动态创建的节点
}

/**
 * 去掉字符串内的所有空格
 * @param {*} ele string
 * @returns
 */
export function trimAll(ele) {
  if (typeof ele === 'string') {
    return ele.split(/[\t\r\f\n\s]*/g).join('')
  } else {
    console.error(
      `${typeof ele} is not the expected type, but the string type is expected`
    )
  }
}


export function formatSeconds(seconds) {
  var minutes = Math.floor(seconds / 60);
  var remainingSeconds = seconds % 60;

  var result = minutes + ":";

  if (remainingSeconds < 10) {
    result += "0" + remainingSeconds;
  } else {
    result += remainingSeconds;
  }

  return result;
}



// fn 是需要防抖处理的函数
// wait 是时间间隔
export function debounce(fn, wait = 50) {
  // 通过闭包缓存一个定时器 id
  let timer = null
  // 将 debounce 处理结果当作函数返回
  // 触发事件回调时执行这个返回函数
  return function (...args) {
    // this保存给context
    const context = this
    // 如果已经设定过定时器就清空上一次的定时器
    if (timer) clearTimeout(timer)

    // 开始设定一个新的定时器，定时器结束后执行传入的函数 fn
    timer = setTimeout(() => {
      fn.apply(context, args)
    }, wait)
  }
}



export const OpenInstallFn = (btn) => {

  const data = OpenInstall.parseUrlParams(); // js中提供的api，解析当前网页url中的查询参数并对data进行赋值
  new OpenInstall(
    {
      // 初始化方法，与openinstall服务器交互，应尽早调用
      appKey: "de06un", // appKey为openinstall为应用分配的唯一id（必须传入）
      onready: function () {
        // 初始化成功回调方法。当初始化完成后，会自动进入
        this.schemeWakeup(); // 尝试使用scheme打开App（主要用于Android以及iOS的QQ环境中）
        const m = this;
        // const button = document.getElementById("downloadButton"); // 为button对象绑定对应id的元素
        btn.onclick = function () {
          // 对应button的点击事件
          m.wakeupOrInstall(); // 此方法为scheme、Universal Link唤醒以及引导下载的作用（必须调用且不可额外自行跳转下载）
          return false;
        };
      },
    },
    data
  ); // 初始化时传入data，作为一键拉起/App传参安装时候的参数
}


const IndKey = [1, 4, 7, 13, 17, 28];
export const decryptFn = (str) => {
  return subtractAtIndex(str, IndKey);
}
function subtractAtIndex(str, indices) {
  let result = '';
  for (let i = 0; i < str.length; i++) {
    if (indices.includes(i)) {
      if (/[0-9]/.test(str[i])) {
        let res = parseInt(str[i]) - 1;
        if (res < 0) {
          result += '9'
        } else {
          result += res
        }

      } else if (/[a-zA-Z]/.test(str[i])) {
        const isUpperCase = str[i] === str[i].toUpperCase();
        let subtractedChar = subtractLetter(str[i]);
        if (isUpperCase) {
          subtractedChar = subtractedChar.toUpperCase();
        }
        result += subtractedChar;
      } else {
        result += str[i];
      }
    } else {
      result += str[i];
    }
  }
  return result;
}

function subtractLetter(letter) {
  const alphabet = 'abcdefghijklmnopqrstuvwxyz';
  const index = alphabet.indexOf(letter.toLowerCase());
  if (index === 0) {
    return 'z';
  } else {
    return alphabet[index - 1];
  }
}

export function isWeChatBrowser() {
  const userAgent = navigator.userAgent.toLowerCase();
  return userAgent.indexOf('micromessenger') !== -1;
}


export function getStartAndEndOfWeek() {
  const date = new Date();
  // 设置周一为一周的第一天
  const dayOfWeek = date.getDay();
  const diff = (dayOfWeek <= 0 ? 7 : dayOfWeek) - 1; // 计算需要减去的时间以得到周一

  // 克隆输入的日期对象，以避免修改原始日期
  const clone = new Date(date.getTime());

  // 计算周一的日期
  const startOfWeek = new Date(clone.getTime() - diff * 24 * 60 * 60 * 1000);

  // 计算周日的日期
  const endOfWeek = new Date(startOfWeek.getTime() + 6 * 24 * 60 * 60 * 1000);

  return {
    startOfWeek: startOfWeek.toISOString().split('T')[0],
    endOfWeek: endOfWeek.toISOString().split('T')[0]
  };
}
export function formatNumberWithCommas(number) {
  if (number != 0 && !number) return
  if (typeof number === 'string') {
    number = Number(number)
  }
  // 将数字转换为字符串，并去掉任何前导或尾随的空格
  let str = number.toString().replace(/\s+/g, '');

  // 使用正则表达式从字符串的右侧开始，每隔三位插入一个逗号
  str = str.replace(/\B(?=(\d{3})+(?!\d))/g, ",");

  return str;
}


export function minutesToHoursAndMinutes(minutes) {
  const hours = Math.floor(minutes / 60); // 整数部分表示小时
  const remainingMinutes = minutes % 60; // 余数部分表示分钟
  return `${hours}小时${remainingMinutes}分钟`;
}

export const formatDate = (date, letterStr = '-') => {
  let year = date.getFullYear();
  let month = (date.getMonth() + 1) > 9 ? date.getMonth() + 1 : '0' + (date.getMonth() + 1);
  let day = date.getDate() > 9 ? date.getDate() : '0' + date.getDate();
  return `${year}${letterStr}${month}${letterStr}${day}`
};